import { mapActions, mapGetters } from 'vuex'

const CONTEXT_MENU_OPTIONS = {
  REASSIGN: 'reassign',
  MARK_AS_COMPLETED: 'mark_as_completed'
}

export default {
  data() {
    return {
      isErrorWatcher: false
    }
  },
  computed: {
    ...mapGetters('api', [
      'getErrorMessage'
    ]),
    contextMenuOptions() {
      const options = [
        {
          type: CONTEXT_MENU_OPTIONS.REASSIGN,
          label: this.$t('assignment.rightPanel.assign.trip.contextMenu.reassign')
        }
      ]
      options.push(
        {
          type: CONTEXT_MENU_OPTIONS.MARK_AS_COMPLETED,
          label: this.$t('assignment.rightPanel.assign.trip.contextMenu.markAsCompleted')
        }
      )
      return options
    },
    markTripAsCompletedError() {
      return this.getErrorMessage('trip/markTripAsCompleted')
    }
  },
  created() {
    if (this.isErrorWatcher) {
      this.watchErrorNotification('markTripAsCompletedError')
    }
  },
  methods: {
    ...mapActions('trip', [
      'markTripAsCompletedRequest'
    ]),
    onSelectTripContextMenu(trip, option) {
      switch (option.type) {
        case CONTEXT_MENU_OPTIONS.REASSIGN:
          if (trip.tasks.some(task => task.externalLogisticsId && task.externalLogisticsType === 'lalamove')) {
            this.showStrongConfirmation({
              message: this.$t('strongConfirmationDialog.cancelTripWithPickupp.lalamoveMessage'),
              onConfirm: () => this.setupReassignTrip(trip)
            })
          } else if (trip.tasks.some(task => task.externalLogisticsId && task.externalLogisticsType === 'pickupp')) {
            this.showStrongConfirmation({
              message: this.$t('strongConfirmationDialog.cancelTripWithPickupp.message'),
              onConfirm: () => this.setupReassignTrip(trip)
            })
          }
          this.setupReassignTrip(trip)
          break
        case CONTEXT_MENU_OPTIONS.MARK_AS_COMPLETED:
          this.markTripAsCompletedRequest(trip.id)
          break
      }
    }
  }
}
