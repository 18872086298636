import { MARK_AS_OPTIONS, getOptionKeySuffix, getOptionLabel } from '@js/mark-as-utils'
import { mapGetters } from 'vuex'
import markTasksAsMixin from '@/mixins/mark-tasks-as-mixin'

const CHECK_PICKUPP = 'check_pickupp'
const CHECK_LALAMOVE = 'check_lalamove'
const REASSIGN = 'reassign'
const REASSIGN_TO_PICKUPP = 'reassign_to_pickupp'
const REASSIGN_TO_LALAMOVE = 'reassign_to_lalamove'

export default {
  mixins: [markTasksAsMixin],
  data() {
    return {
      selectedTask: null
    }
  },
  computed: {
    ...mapGetters('sessionUser', [
      'features'
    ]),
    contextMenuOptions() {
      const options = []
      if (this.selectedTask && this.selectedTask.externalLogisticsId) {
        options.push(
          this.selectedTask.externalLogisticsType === 'lalamove' ? {
            type: CHECK_LALAMOVE,
            key: CHECK_LALAMOVE,
            label: this.$t('assignment.rightPanel.assign.task.contextMenu.checkLalamove')
          } : {
            type: CHECK_PICKUPP,
            key: CHECK_PICKUPP,
            label: this.$t('assignment.rightPanel.assign.task.contextMenu.checkPickupp')
          }
        )
      }

      options.push(
        {
          type: REASSIGN,
          key: REASSIGN,
          label: this.$t('assignment.rightPanel.assign.task.contextMenu.reassign')
        }
      )

      if (this.selectedTask && !this.selectedTask.externalLogisticsId) {
        options.push(
          this.features.useLalamove ? {
            type: REASSIGN_TO_LALAMOVE,
            key: REASSIGN_TO_LALAMOVE,
            label: this.$t('assignment.rightPanel.assign.task.contextMenu.reassignToLalamove')
          } : {
            type: REASSIGN_TO_PICKUPP,
            key: REASSIGN_TO_PICKUPP,
            label: this.$t('assignment.rightPanel.assign.task.contextMenu.reassignToPickupp')
          }
        )
      }

      options.push(
        ...MARK_AS_OPTIONS.map(option => {
          return {
            ...option,
            key: getOptionKeySuffix(option),
            label: getOptionLabel(option)
          }
        })
      )
      return options
    }
  },
  methods: {
    onSelectTaskContextMenu(task, option) {
      switch (option.type) {
        case CHECK_PICKUPP:
          window.open(`${process.env.VUE_APP_PICKUPP_URL}/orders/${task.externalLogisticsId}`, '_blank')
          break
        case CHECK_LALAMOVE:
          window.open(task.externalLogisticsTrackingUrl, '_blank')
          break
        case REASSIGN:
          if (task.externalLogisticsId) {
            return this.showStrongConfirmation({
              onConfirm: () => this.setupReassignTask(task)
            })
          }
          this.setupReassignTask(task)
          break
        case REASSIGN_TO_PICKUPP:
        case REASSIGN_TO_LALAMOVE:
          this.$emit('show-pickupp-form', task)
          break
        default:
          this.markTasksAs([task], option)
      }
    },
    setupReassignTask() {
      throw new Error('setupReassignTask method (accepts a [task] argument) needs to be overridden')
    }
  }
}
