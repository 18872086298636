<template>
  <div class="assignment-right-panel">
    <div
      class="right-panel-header"
      :class="{'right-panel-assign-header': isAssigning}">
      <template v-if="!isAssigning">
        {{ $t('assignment.rightPanel.header') }}
      </template>
      <template v-else>
        <div class="assign-header">
          {{ $t('assignment.rightPanel.assign.header') }}
        </div>
        <div class="assign-tasks-count">
          {{ $tc('assignment.rightPanel.assign.tasksCount', assigningTripTasks.length, { count: assigningTripTasks.length }) }}
        </div>
        <b-icon
          icon="times"
          @click.native="cancelAssignment"/>
      </template>
    </div>
    <template v-if="!isAssigning">
      <assignment-courier-group
        v-for="courierGroup in courierGroups"
        :key="`right_panel_courier_group_${courierGroup.name}`"
        :courierGroup="courierGroup"
        @trip-contextmenu="onRightClickTrip"
        @task-contextmenu="onRightClickTask"/>
    </template>
    <template v-else>
      <div class="right-panel-assign-actions">
        <search-couriers :selectedCourier.sync="localSelectedCourier"/>
        <b-button
          type="is-primary"
          @click="assignTrips">
          {{ $t('assignment.rightPanel.assign.buttonTitle') }}
        </b-button>
        <b-button
          type="is-text"
          @click="deselectAll">
          {{ $t('assignment.rightPanel.assign.deselectAll.confirm') }}
        </b-button>
        <div class="button-container">
          <b-button
            type="is-secondary"
            @click="batchAssignToPickupp">
            {{ $t(`assignment.rightPanel.assign.${externalLogisticsType === 'lalamove' ? 'batchAssignToLalamove' : 'batchAssignToPickupp'}`) }}
          </b-button>
        </div>
      </div>
      <assignment-trip
        v-for="trip in assigningTrips"
        :key="`assignment_new_trip_${trip.id}`"
        :trip="trip"
        :isEditing="true"
        :isSelected="selectedAssigningTrip && trip.id === selectedAssigningTrip.id"
        @show-strong-confirmation="showStrongConfirmation"/>
      <div
        class="right-panel-assign-footer"
        @click="addNewTrip">
        {{ $t('assignment.rightPanel.assign.trip.add') }}
      </div>
    </template>
    <trip-context-menu
      v-show="contextMenuType === CONTEXT_MENUS.TRIP"
      ref="tripContextMenu"
      @select="onSelectTripContextMenu"/>
    <task-context-menu
      v-show="contextMenuType === CONTEXT_MENUS.TASK"
      ref="taskContextMenu"
      @select="onSelectTaskContextMenu"/>
    <strong-confirmation-dialog ref="strong-confirmation-dialog"/>
  </div>
</template>

<script>
import { cloneTrips, formatExistingTrips, formatNewTrips, isTripDispatched } from '@js/trip-utils'
import { mapActions, mapGetters } from 'vuex'
import apiWithFiltersMixin from '@/mixins/api-with-filters-mixin'
import { formatDate } from '@js/utils'
import fulfilmentChannelMixin from '@/mixins/fulfilment-channel-mixin'
import taskContextMenuMixin from '@/mixins/task-context-menu-mixin'
import tripContextMenuMixin from '@/mixins/trip-context-menu-mixin'

const AssignmentCourierGroup = () => import('./AssignmentCourierGroup')
const AssignmentTrip = () => import('@components/AssignmentTrip')
const SearchCouriers = () => import('./SearchCouriers')
const StrongConfirmationDialog = () => import('@components/StrongConfirmationDialog')
const TaskContextMenu = () => import('./TaskContextMenu')
const TripContextMenu = () => import('./TripContextMenu')

const CONTEXT_MENUS = {
  TRIP: 'trip',
  TASK: 'task'
}

export default {
  name: 'assignment-right-panel',
  components: {
    AssignmentCourierGroup,
    AssignmentTrip,
    SearchCouriers,
    StrongConfirmationDialog,
    TaskContextMenu,
    TripContextMenu
  },
  mixins: [
    apiWithFiltersMixin,
    fulfilmentChannelMixin,
    taskContextMenuMixin,
    tripContextMenuMixin
  ],
  data() {
    return {
      CONTEXT_MENUS,
      contextMenuType: null,
      // override mixin
      isErrorWatcher: true
    }
  },
  computed: {
    ...mapGetters('api', [
      'getLoading',
      'getErrorMessage'
    ]),
    ...mapGetters('courier', [
      'courierGroups',
      'selectedCourier'
    ]),
    ...mapGetters('sessionUser', [
      'features'
    ]),
    ...mapGetters('task', [
      'reassigningTask'
    ]),
    ...mapGetters('trip', [
      'assigningTrips',
      'assigningTripTasks',
      'getCourierTripsClone',
      'reassigningTripId',
      'selectedAssigningTrip'
    ]),
    externalLogisticsType() {
      if (this.features.useLalamove) {
        return 'lalamove'
      }
      return 'pickupp'
    },
    isAssigning() {
      return this.assigningTripTasks.length > 0
    },
    localSelectedCourier: {
      get() {
        return this.selectedCourier
      },
      set(value) {
        if (this.selectedCourier === value) return

        this.setSelectedCourier(value)

        // return error if no change in courier
        const courierTripsClone = value ? this.getCourierTripsClone(value.id) : []
        if (this.reassigningTask) {
          if (courierTripsClone.some(trip => trip.id === this.reassigningTask.tripId)) {
            this.$buefy.dialog.alert({
              message: this.$t('assignment.rightPanel.assign.error.reassignSameCourier'),
              onConfirm: () => this.setSelectedCourier(null)
            })
            return
          }
        }

        // always include reassigning trip
        const hasReassigningTrip = courierTripsClone.some(trip => trip.id === this.reassigningTripId)
        const retainTrips = this.assigningTrips.filter(trip => {
          if (!hasReassigningTrip && this.reassigningTripId && this.reassigningTripId === trip.id) {
            return true
          }
          return trip.isNew
        })
        this.setAssigningTrips(courierTripsClone.concat(retainTrips))
      }
    },
    isApiLoading() {
      return this.getLoading(
        'task/unassignTask',
        'trip/assignTrips',
        'trip/reassignTrip',
        'trip/removeTrip',
        'trip/updateTrips'
      )
    },
    assignError() {
      const errors = [
        this.getErrorMessage('task/unassignTask'),
        this.getErrorMessage('trip/assignTrips'),
        this.getErrorMessage('trip/reassignTrip'),
        this.getErrorMessage('trip/removeTrip'),
        this.getErrorMessage('trip/updateTrips')
      ].filter(error => error)
      if (errors.length > 0) {
        return errors.join('; ')
      }
      return null
    }
  },
  created() {
    this.resetCourierStore()
    this.resetTripStore()

    this.watchErrorNotification('assignError')
  },
  methods: {
    ...mapActions('courier', [
      'resetCourierStore',
      'getSortedCouriersRequest',
      'setSelectedCourier'
    ]),
    ...mapActions('task', [
      'setReassigningTask',
      'batchAssignTasksToPickuppRequest'
    ]),
    ...mapActions('trip', [
      'resetTripStore',
      'addNewTrip',
      'addTripTask',
      'assignTripsRequest',
      'getTripsRequest',
      'reassignTripRequest',
      'removeTrip',
      'removeTripTask',
      'resetAssigningTrips',
      'setAssigningTrips',
      'setReassigningTripId',
      'updateTripsRequest'
    ]),
    // override method in mixin
    onReceived(data) {
      if (data.object === 'Trip') {
        // TODO: check if will disrupt any coordinator/hub manager actions
        this.debounceRequest(this.getTrips)
      }
    },
    // override method in mixin
    apiRequest() {
      // no need to call $cable.subscribe again as already subscribed in AssignmentLeftPanel

      this.getSortedCouriersRequest({
        servingDate: formatDate(this.selectedDate),
        hubId: this.selectedHubIds[0]
      })
      this.getTrips()
    },
    getTrips() {
      this.getTripsRequest({
        servingDate: formatDate(this.selectedDate),
        timeRangeStart: this.selectedTimeRange[0],
        timeRangeEnd: this.selectedTimeRange[1],
        hubIds: this.selectedHubIds,
        groupBy: 'courier',
        dispatched: false,
        unfinishedOnly: true
      })
    },
    cancelAssignment() {
      this.$buefy.dialog.confirm({
        title: this.$t('assignment.rightPanel.assign.cancel.title'),
        message: this.$t('assignment.rightPanel.assign.cancel.message'),
        confirmText: this.$t('assignment.rightPanel.assign.cancel.confirm'),
        cancelText: this.$t('assignment.rightPanel.assign.cancel.cancel'),
        type: 'is-warning',
        hasIcon: true,
        onConfirm: () => this.resetAssigningTrips()
      })
    },
    deselectAll() {
      const hasLalamoveTask = this.assigningTrips.some(trip => {
        if (trip.isNew) {
          return false
        }
        return trip.tasks.some(task => task.externalLogisticsId && task.externalLogisticsType === 'lalamove')
      })
      if (hasLalamoveTask) {
        return this.showStrongConfirmation({
          message: this.$t('strongConfirmationDialog.cancelTripWithPickupp.lalamoveMessage'),
          onConfirm: () => this.showDeselectAllConfirmation()
        })
      }

      const hasPickuppTask = this.assigningTrips.some(trip => {
        if (trip.isNew) {
          return false
        }
        return trip.tasks.some(task => task.externalLogisticsId && task.externalLogisticsType === 'pickupp')
      })
      if (hasPickuppTask) {
        return this.showStrongConfirmation({
          message: this.$t('strongConfirmationDialog.cancelTripWithPickupp.message'),
          onConfirm: () => this.showDeselectAllConfirmation()
        })
      }

      this.showDeselectAllConfirmation()
    },
    showDeselectAllConfirmation() {
      this.$buefy.dialog.confirm({
        title: this.$t('assignment.rightPanel.assign.deselectAll.title'),
        message: this.$t('assignment.rightPanel.assign.deselectAll.message'),
        confirmText: this.$t('assignment.rightPanel.assign.deselectAll.confirm'),
        cancelText: this.$t('assignment.rightPanel.assign.deselectAll.cancel'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.unassignAllTrips()
      })
    },
    unassignAllTrips() {
      this.assigningTrips.forEach(trip => {
        if (!trip.isNew) {
          this.removeTrip(trip)
        }
      })
      this.resetAssigningTrips()
    },
    assignTrips() {
      if (!this.selectedCourier) {
        this.$buefy.dialog.alert({
          message: this.$t('assignment.rightPanel.assign.error.noCourier')
        })
        return
      }

      // exclude empty trips
      const newTrips = []
      const existingTrips = []
      this.assigningTrips.forEach(trip => {
        // don't do anything if trip is already dispatched
        if (isTripDispatched(trip)) return
        // handle reassignment separately
        if (this.reassigningTripId && this.reassigningTripId === trip.id) return

        if (trip.isNew && trip.tasks.length > 0) {
          newTrips.push(trip)
        } else if (!trip.isNew && trip.tasks.length > 0) {
          existingTrips.push(trip)
        } else if (!trip.isNew) {
          this.removeTrip(trip)
        }
      })

      // FIXME: when a task is moved from an existing trip to a new trip,
      // the task needs to be unassigned first before it can be assigned

      const handleTrips = () => {
        this.assignNewTrips(newTrips)
        this.updateExistingTrips(existingTrips)
      }

      if (this.reassigningTripId) {
        this.addApiListeners('trip/reassignTrip', this.onReassignTripComplete)
        this.reassignTripRequest({
          tripId: this.reassigningTripId,
          specialistId: this.selectedCourier.id
        })
      } else if (this.reassigningTask) {
        // wait for remove trip task to complete, before handling trips
        const onRemoveTripTaskComplete = () => {
          this.removeApiListeners('task/unassignTask', onRemoveTripTaskComplete)
          this.removeApiListeners('trip/removeTrip', onRemoveTripTaskComplete)

          handleTrips()
        }

        this.addApiListeners('task/unassignTask', onRemoveTripTaskComplete)
        this.addApiListeners('trip/removeTrip', onRemoveTripTaskComplete)
        this.removeTripTask(this.reassigningTask)
        return
      }

      handleTrips()
    },
    assignNewTrips(newTrips) {
      if (newTrips.length > 0) {
        this.addApiListeners('trip/assignTrips', this.onAssignTripsComplete)
        this.assignTripsRequest({
          servingDate: formatDate(this.selectedDate),
          hubId: this.selectedHubIds[0],
          specialistId: this.selectedCourier.id,
          trips: formatNewTrips(newTrips)
        })
      }
    },
    updateExistingTrips(existingTrips) {
      if (existingTrips.length > 0) {
        this.addApiListeners('trip/updateTrips', this.onUpdateTripsComplete)
        this.updateTripsRequest({
          trips: formatExistingTrips(existingTrips)
        })
      }
    },
    onReassignTripComplete() {
      this.removeApiListeners('trip/reassignTrip', this.onReassignTripComplete)
      this.completeAssignment()
    },
    onAssignTripsComplete() {
      this.removeApiListeners('trip/assignTrips', this.onAssignTripsComplete)
      this.completeAssignment()
    },
    onUpdateTripsComplete() {
      this.removeApiListeners('trip/updateTrips', this.onUpdateTripsComplete)
      this.completeAssignment()
    },
    completeAssignment() {
      if (!this.isApiLoading && !this.assignError) {
        this.openToast({
          message: this.$t('assignment.rightPanel.assign.successMessage'),
          type: 'is-success'
        })
        this.resetAssigningTrips()
      }
    },
    onRightClickTrip(event, trip) {
      event.preventDefault()
      this.contextMenuType = CONTEXT_MENUS.TRIP
      this.$refs.tripContextMenu.open(trip)
    },
    onRightClickTask(event, task) {
      event.preventDefault()
      this.contextMenuType = CONTEXT_MENUS.TASK
      this.$refs.taskContextMenu.open(task)
    },
    showStrongConfirmation(data) {
      this.$refs['strong-confirmation-dialog'].open({
        message: this.$t(`strongConfirmationDialog.cancelPickupp.${this.externalLogisticsType === 'lalamove' ? 'lalamoveMessage' : 'message'}`),
        input: this.$t('strongConfirmationDialog.cancelPickupp.input'),
        confirmText: this.$t('strongConfirmationDialog.cancelPickupp.confirmText'),
        cancelText: this.$t('strongConfirmationDialog.cancelPickupp.cancelText'),
        ...data
      })
    },
    setupReassignTrip(trip) {
      this.setReassigningTripId(trip.id)
      this.setSelectedCourier(trip.fulfilmentUser)
      this.setAssigningTrips(cloneTrips([trip]))
    },
    setupReassignTask(task) {
      this.setReassigningTask(task)
      this.addTripTask(task)
    },
    batchAssignToPickupp() {
      if (!this.selectedAssigningTrip.tasks.length) return

      this.$eventBus.$off('task/batchAssignTasksToPickuppSuccess', this.onBatchAssignToPickuppComplete)
      this.$eventBus.$on('task/batchAssignTasksToPickuppSuccess', this.onBatchAssignToPickuppComplete)
      this.batchAssignTasksToPickuppRequest(this.selectedAssigningTrip.tasks.map((t) => t.id))
    },
    onBatchAssignToPickuppComplete() {
      this.$eventBus.$off('task/batchAssignTasksToPickuppSuccess', this.onBatchAssignToPickuppComplete)
      this.resetAssigningTrips()
      this.openToast({
        message: this.$t('assignment.rightPanel.assign.successMessage'),
        type: 'is-success'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.assignment-right-panel {
  .right-panel-header {
    padding: $space-xs;
    font-weight: bold;
  }

  .right-panel-assign-header {
    display: flex;
    align-items: center;
    color: $black;
    background-color: $white;

    .assign-header {
      flex-grow: 1;
      font-weight: bold;
      color: $grey-darker;
      @extend %body;
    }

    .assign-tasks-count {
      position: absolute;
      left: 0;
      width: 100%;
      text-align: center;
      @extend %display_medium;
    }

    span.icon {
      position: relative;
      cursor: pointer;
    }
  }

  .right-panel-assign-actions {
    padding: $space-xs;
    background-color: $white;

    .search-couriers {
      margin-bottom: $space-s;
    }

    .button {
      width: calc(50% - #{$space-xxs} / 2);
      @extend %body;

      & + .button {
        margin-left: $space-xxs;
      }
    }

    .button-container {
      margin-top: $space-xxs;
      margin-bottom: $space-xxs;
    }
  }

  .right-panel-assign-footer {
    padding: $space-xs;
    text-decoration: underline;
    cursor: pointer;
    border-top: 1px solid $grey-dark;
  }
}
</style>
